import React, { FC, useState } from 'react';
import GibsonGuitars from "../../assets/images/GibsonGuitars.png";
import CrossSign from '../../assets/svg/CrossIcon';
import GibsonLogoBlack from "../../assets/svg/Desktop/BrandSelect/Gibson/GibsonLogoBlack.svg"; 
import Button from '../Button';
import useIsMobileViewport from '../../hooks/useIsMobileViewport';
import * as styles from "./notesCard.module.sass"; 

export interface NotesCardProps{
    title: string;
    p: string;
    hasBlackBorder?: boolean;
}

const NotesCard: FC<NotesCardProps> = (props) => {
    const { title, p,hasBlackBorder} = props;
    const { isMobile } = useIsMobileViewport();
    return (
        <div
            className={` border-t-8 ${hasBlackBorder ? "border-black" : "border-gold-2"} shadow-[4px_4px_12px_0px_rgba(0,0,0,0.2)] p-6 gap-4 flex flex-col flex-1`}>
            <h3 className='uppercase text-2xl font-bold'>{title} </h3>
            <p className='text-sm'>{p}</p>
        </div>
    );
};

export default NotesCard;