import React, { FC } from "react";
import useIsMobileViewport from "../../hooks/useIsMobileViewport";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "./index.sass";
import GoldLogo from "../../assets/svg/Desktop/GibsonGives/GoldLogo.svg"; 
// Desktop
import EpiEmpowered from "../../assets/svg/Desktop/GibsonGives/EpiEmpowered.svg"; 
import KramerKares from "../../assets/svg/Desktop/GibsonGives/KramerKares.svg"; 
import KRKKares from "../../assets/svg/Desktop/GibsonGives/KRKKares.svg"; 
import MesaMission from "../../assets/svg/Desktop/GibsonGives/MesaMission.svg"; 
//Mobile
import EpiEmpoweredMob from "../../assets/svg/Mobile/GibsonGives/EpiEmpowered.png";
import KramerKaresMob from "../../assets/svg/Mobile/GibsonGives/KramerKares.png";
import KRKKaresMob from "../../assets/svg/Mobile/GibsonGives/KRKKares.png";
import MesaMissionMob from "../../assets/svg/Mobile/GibsonGives/MesaMission.png"; 
import SideBySideCard from "../../components/SideBySideCard";
import { NotesData, SideBySideCardData } from "./data";
import NotesCard from "../../components/NotesCard";

export interface GibsonGivesScreenProps { }

const GibsonGivesScreen: FC<GibsonGivesScreenProps> = (props) => {
    const { isMobile } = useIsMobileViewport();
    return (
        <div className='gives-screen h-screen snap-y snap-mandatory overflow-y-scroll '>
            <Header shrinkOnScroll={isMobile} />
            <div className='relative'>
                <div
                    className={`relative bg-cover bg-no-repeat bg-center GivesBg mt-[-10vh] md:mt-0`}>
                    {isMobile ? (
                        <div className='text-white container-margin absolute bottom-[2%]'>
                            <img src={GoldLogo} className='pb-4' />
                            <h1 className='uppercase font-bold  text-6xl md:text-7xl leading-[70px] border-0 border-t-4 border-gold  pt-4'>
                                gibson gives
                            </h1>
                            <p className='uppercase text-xs leading-[35px]'>
                                bring awareness to the charitable spirit
                            </p>
                        </div>
                    ) : (
                        <div className='absolute left-[58vw] text-white container-margin top-[20%] py-4'>
                            <img src={GoldLogo} className='pb-4' />
                            <div className='border-0 border-t-4 border-gold pt-4'>
                                <h1 className='uppercase font-bold  text-6xl md:text-7xl leading-[70px]'>
                                    gibson gives
                                </h1>
                                <p className='uppercase text-sm leading-[35px]'>
                                    bring awareness to the charitable spirit
                                </p>
                            </div>
                        </div>
                    )}
                </div>

                <div className='container-padding flex flex-col justify-center bg-gray-3'>
                    <p className='text-sm my-12 text-center'>
                        In 2021, Gibson Gives launched the following programs to
                        further bring {!isMobile && <br />}
                        awareness to the charitable spirit shared by our family
                        of brands:
                    </p>
                    <>
                        {isMobile ? (
                            <div className='flex flex-col justify-around mt-6 mb-16 '>
                                <div className='flex flex-row justify-around mb-4'>
                                    <img src={EpiEmpowered} />
                                    <img src={KramerKares} />
                                </div>
                                <div className='flex flex-row justify-around'>
                                    <img src={KRKKares} />
                                    <img src={MesaMission} /> 
                                </div>
                            </div>
                        ) : (
                            <div className='flex flex-row justify-around my-12 '>
                                <img src={EpiEmpowered} />
                                <img src={KramerKares} />
                                <img src={KRKKares} />
                                <img src={MesaMission} />
                            </div>
                        )}
                    </>
                    <div>
                        {SideBySideCardData(isMobile).map((data) => {
                            return <SideBySideCard {...data} />;
                        })}
                    </div>

                    <div className='mb-28 flex flex-col md:grid md:grid-cols-[auto_auto_auto] md:flex-row justify-between gap-8'>
                        {NotesData.map((data) => {
                            return <NotesCard {...data} />;
                        })}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default GibsonGivesScreen;
