import React, { FC, useState } from 'react';
import Carousel from '../Carousel';
import useIsMobileViewport from '../../hooks/useIsMobileViewport';
import Button from '../Button';
import * as styles from "./sideBySide.module.sass"; 

export interface SideBySideCardProps {
    imgOnLeft?: boolean;
    title: string;
    p1: string;
    p2?: string;
    img: any[];
    link?: string;
}

const SideBySideCard: FC<SideBySideCardProps> = (props) => {
    const { imgOnLeft = false, title, p1, p2, img , link} = props;
    const { isMobile } = useIsMobileViewport();
    return (
        <div
            className={`${styles.sideBySide} flex flex-row justify-between mb-20 md:mb-28`}>
            {imgOnLeft && !isMobile && (
                <div
                    className={`flex-1  w-[40vw] ${styles.sideBySideCarousel}`}>
                    <Carousel
                        classes='flex-1 my-5'
                        hasProgressBar={img.length > 1}
                        progressBarInMobView>
                        {img.map((item, id) => {
                            return <img src={item} className='!w-[40vw]' />;
                        })}
                    </Carousel>
                </div>
            )}
            <div className='flex-1 flex flex-col justify-evenly md:w-[40vw]'>
                <h1 className='text-5xl	md:text-6xl	uppercase font-bold  mb-[2rem] md:mb-0'>
                    {title}
                </h1>
                <div className='flex flex-col gap-[2rem] md:w-[78%]'>
                    <p className='text-sm '>{p1}</p>
                    {p2 && <p className='text-sm '>{p2}</p>}
                </div>
                {link && (
                    <div className='block md:flex  mt-[2rem] md:mt-0'>
                        <Button
                            hasArrow
                            hasUpperCase
                            isBlack
                            externalLink={link}>
                            Learn More
                        </Button>
                    </div>
                )}
                {isMobile && (
                    <div
                        className={`flex-1  mt-[1rem] ${styles.sideBySideCarousel}`}>
                        <Carousel
                            classes='flex-1 my-5'
                            hasProgressBar={img.length > 1}
                            progressBarInMobView>
                            {img.map((item, id) => {
                                return (
                                    <img
                                        src={item}
                                        className={`${styles.carouselWdt}`}
                                    />
                                );
                            })}
                        </Carousel>
                    </div>
                )}
            </div>
            {!imgOnLeft && !isMobile && (
                <div
                    className={`flex-1  w-[40vw] ${styles.sideBySideCarousel}`}>
                    <Carousel
                        classes='flex-1 my-5'
                        hasProgressBar={img.length > 1}
                        progressBarInMobView>
                        {img.map((item, id) => {
                            return <img src={item} className='!w-[40vw]' />;
                        })}
                    </Carousel>
                </div>
            )}
        </div>
    );
};

export default SideBySideCard;