import React from "react";
import type { HeadFC } from "gatsby";
import GibsonGivesScreen from "../../screens/GibsonGivesScreen";

const GibsonGivesPage = () => {
    return <GibsonGivesScreen />;
};

export default GibsonGivesPage;

export const Head: HeadFC = () => <title>Gibson Gives Page Overview</title>;
