import { SideBySideCardProps } from "../../components/SideBySideCard";
//Desktop
import Carousel1Img1 from "../../assets/images/Desktop/GibsonGives/Carousel1-Img1.jpg"; 
import Carousel1Img2 from "../../assets/images/Desktop/GibsonGives/Carousel1-Img2.jpg"; 
import Carousel2Img1 from "../../assets/images/Desktop/GibsonGives/Carousel2-Img1.jpg"; 

//Mobile
import Carousel1Img1Mob from "../../assets/images/Mobile/GibsonGives/Carousel1-Img1.jpg"; 
import Carousel1Img2Mob from "../../assets/images/Mobile/GibsonGives/Carousel1-Img2.jpg";
import Carousel2Img1Mob from "../../assets/images/Mobile/GibsonGives/Carousel2-Img1.jpg"; 
import { NotesCardProps } from "../../components/NotesCard";

export const SideBySideCardData = (isMobile?: boolean): SideBySideCardProps[] => [
    {
        imgOnLeft: false,
        title: "in service to the community",
        p1: "Since its relaunch in 2019, Gibson Gives — a 501(c)(3) — has raised over $2.5 million worldwide, and gifted thousands of instruments in pursuit of our mission of making music matter more, one guitar at a time. Gibson Gives partners with our local communities and other global organizations to promote music programs and musicians. 100% of all donations go towards our programs.",
        p2: "In 2021, Gibson Gives launched the following programs to further bring awareness to the charitable spirit shared by our family of brands.",
        img: [
            isMobile ? Carousel1Img1Mob : Carousel1Img1,
            isMobile ? Carousel1Img2Mob : Carousel1Img2,
        ],
    },
    {
        imgOnLeft: true,
        title: "Gibson Gives programs",
        p1: "In addition to our brand-specific programs, we have created several purpose-driven initiatives under Gibson Gives that channel the inspiring generosity, empathy, and sense of purpose shared by our employees and the broader music community. ",
        img: [isMobile ? Carousel2Img1Mob : Carousel2Img1],
        link: "https://www.gibsonfoundation.org/",
    },
];

export const NotesData: NotesCardProps[] = [
    {
        title: "Play it Forward ",
        p: "Gibson Gives launched the Play It Forward program in February 2020 to help local and national musicians in need by replacing guitars lost in the devastating Nashville tornados. ",
    },
    {
        title: "Tempo",
        p: "Losing even one musician to overdose is too many. The TEMPO program works to train a network of musicians and others in the music industry in the use of naloxone, to empower them to save lives.",
    },
    {
        title: "Gibson Gives Back",
        p: "The dedicated folks who work for Gibson — in Nashville, Bozeman, and Petaluma — are committed to helping their communities. Gibson Gives Back leverages our collective resources to support local initiatives identified by our employees.",
    },
    {
        title: "guitars for vets",
        p: "Gibson Gives launched the Play It Forward program in February 2020 to help local and national musicians in need by replacing guitars lost in the devastating Nashville tornados. ",
    },
    {
        title: "playing for change",
        p: "Losing even one musician to overdose is too many. The TEMPO program works to train a network of musicians and others in the music industry in the use of naloxone, to empower them to save lives.",
    },
    {
        title: "musicians on call",
        p: "The dedicated folks who work for Gibson — in Nashville, Bozeman, and Petaluma — are committed to helping their communities. Gibson Gives Back leverages our collective resources to support local initiatives identified by our employees.",
    },
    {
        title: "women who rock",
        p: "Gibson Gives launched the Play It Forward program in February 2020 to help local and national musicians in need by replacing guitars lost in the devastating Nashville tornados. ",
    },
    {
        title: "notes for notes",
        p: "Losing even one musician to overdose is too many. The TEMPO program works to train a network of musicians and others in the music industry in the use of naloxone, to empower them to save lives.",
    },
    {
        title: "give a note",
        p: "The dedicated folks who work for Gibson — in Nashville, Bozeman, and Petaluma — are committed to helping their communities. Gibson Gives Back leverages our collective resources to support local initiatives identified by our employees.",
    },
];