// extracted by mini-css-extract-plugin
export var carouselWdt = "sideBySide-module--carouselWdt--9de57";
export var desktopMargin = "50px";
export var doublexl = "1536";
export var lg = "1024";
export var md = "768";
export var mobileMargin = "18px";
export var sideBySide = "sideBySide-module--sideBySide--e26c0";
export var sideBySideCarousel = "sideBySide-module--sideBySideCarousel--19f4d";
export var sm = "640";
export var xl = "1280";